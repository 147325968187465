import {applyTimezone, dateShortFormat, datetimeFormat, translate} from '../utils';
import useGlobalSpinner from '../hooks/useGlobalSpinner';
import ChannelManagerNoticeDescription from './ChannelManagerNoticeDescription';

export default function ChannelManagerNotice({notice, afterChangeStatus, isOpenable = false}) {
    const [,setIsLoading] = useGlobalSpinner(false);

    let roomDescription = '';
    if (notice.reservation_room) {
        roomDescription = `${translate('frontend', 'CMM_NOTICE_ROOM_LABEL', {room_nr: notice.reservation_room.room_name})} ${dateShortFormat(notice.reservation_room.reservation_from * 1000)}-${dateShortFormat(notice.reservation_room.reservation_to * 1000)} •`;
    }

    const changeStatus = async (newStatus) => {
        setIsLoading(true);
        const queryParams = new URLSearchParams({key: notice.notice_key});
        const formData = new FormData();
        formData.append('status', newStatus);
        const response = await fetch(`/channel-manager-notice/change-status?${queryParams}`, {method: 'POST', body: formData})
            .then(response => response.json());

        setIsLoading(false);
        afterChangeStatus(response);
        document.dispatchEvent(new CustomEvent('channelManagerNotice:update', {detail: response}));
    }

    let action;
    if (notice.resolved_at) {
        action = <a className={'btn btn-default-clear btn-sm'} onClick={() => changeStatus('unresolved')}>{translate('frontend', 'CMM_NOTICES_MODAL_ACTION_UNRESOLVE')}</a>;
    } else {
        action = <a className={'btn btn-default-clear btn-sm'} onClick={() => changeStatus('resolved')}>{translate('frontend', 'CMM_NOTICES_MODAL_ACTION_RESOLVE')}</a>;
    }

    return <div className={'alert alert-warning'}>
        <div className={'alert-body'}>
            <p>
                {notice.reservation?.key && isOpenable
                    ? <><a href={'/reservation/update/' + notice.reservation.key}>{notice.reservation.code}</a> • </>
                    : null
                }
                {datetimeFormat(applyTimezone(notice.created_at * 1000))} • {roomDescription} <ChannelManagerNoticeDescription description={notice.description} />
            </p>
            {notice.resolved_at
                ? <p className={'italic'}>{translate('frontend', 'CMM_NOTICES_MODAL_RESOLVED_BY_LABEL', {username: notice.resolved_by.username ?? translate('yii', '(not set)'), datetime: datetimeFormat(applyTimezone(notice.resolved_at * 1000))})}</p>
                : null
            }
        </div>
        <div className={'hstack gap-1'}>
            {notice.reservation?.key && isOpenable
                ? <a href={`/reservation/update/${notice.reservation.key}`} className={'btn btn-default-clear btn-sm'}>{translate('frontend', 'CMM_NOTICES_MODAL_ACTION_OPEN_RESERVATION')}</a>
                : null
            }
            {action}
        </div>
    </div>;
}
