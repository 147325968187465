import {useLayoutEffect, useState} from 'react';
import {startLoadingIndicator, stopLoadingIndicator} from '../loading';

export default function useGlobalSpinner(initialState) {
    const [isLoading, setIsLoading] = useState(initialState);

    useLayoutEffect(() => {
        if (isLoading) {
            startLoadingIndicator();
            return () => stopLoadingIndicator();
        }
    }, [isLoading]);

    return [isLoading, setIsLoading];
}
