import {startLoadingIndicator, stopLoadingIndicator} from './loading';
import $ from 'jquery';
import {translate} from './utils';
import {createRoot} from 'react-dom/client';
import Shell from './components/Shell';
import ErrorBoundary from './components/ErrorBoundary';
import {ErrorModalContent} from './components/ModalContent';
import ModalContainer from './contexts/ModalContainer';

/**
 * @param {string} id
 * @param {''|'model-dialog_cus'|'modal-sm'|'modal-lg'} [size='']
 * @param {boolean} [animation=false]
 * @param {boolean} [bringToFront=false] If modal is already initialized, moves it to bottom of body, thus ensuring it's on top
 * @param {boolean} [keyboard=false] Closes the modal when escape key is pressed
 */
function ensureModalContainer(id, size = '', animation = false, bringToFront = false, keyboard = false) {
    let modalContainer = document.getElementById(id);

    if (!modalContainer) {
        document.body.insertAdjacentHTML('beforeend', `
        <div id="${id}" class="modal ${animation ? 'fade' : ''}" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="${keyboard ? 'true' : 'false'}">
            <div class="modal-dialog ${size ? size : ''}">
                <div class="modal-content">
                </div>
            </div>
        </div>
       `);
        modalContainer = document.getElementById(id);
    } else if (bringToFront) {
        // Moves to bottom of body to ensure modal is topmost
        document.body.appendChild(modalContainer);
    }

    return modalContainer;
}

let modalCounter = 0;

/**
 * @param {''|'model-dialog_cus'|'modal-sm'|'modal-lg'} [modalOptions.size='']
 * @param {boolean} [modalOptions.animation=false]
 * @param {boolean} [modalOptions.keyboard=false] Closes the modal when escape key is pressed
 */
function showModal(component, props = {}, modalOptions = {}) {
    const id = `m${++modalCounter}`;

    startLoadingIndicator();
    Promise.all([component, props]).then(([Component, props]) => {
        const modalContainer = ensureModalContainer(id, modalOptions.size, modalOptions.animation || false, false, modalOptions.keyboard || false);
        const modalContent = modalContainer.querySelector('.modal-content');
        const reactRoot = createRoot(modalContent, {
            identifierPrefix: id,
        });

        const $modalContainer = $(modalContainer);
        // Mounted content might assume the container is visible. E.g., to set focus.
        $modalContainer.on('show.bs.modal', (event) => {
            if (event.target !== modalContainer) {
                // Avoid nested events
                return;
            }

            reactRoot.render(<Shell>
                <ErrorBoundary fallback={error => <ErrorModalContent message={error}/>}>
                    <ModalContainer.Provider value={$modalContainer}>
                        <Component {...props}/>
                    </ModalContainer.Provider>
                </ErrorBoundary>
            </Shell>);
        }).on('hidden.bs.modal', (event) => {
            // Keep content mounted until closing animation (if any) has finished.
            if (reactRoot && event.target === modalContainer) {
                reactRoot.unmount();
                modalContainer.remove();
            }
        });

        $modalContainer.modal('show');
    }).finally(() => {
        stopLoadingIndicator();
    });
}

/**
 * Opens a full create reservation modal. Only one of "category" and "room" is required
 *
 * @param {string} type
 * @param {string} startDate Y-m-d
 * @param {string} endDate Y-m-d
 * @param {?number} category
 * @param {?number} room
 * @param {callback} onCreate
 * @returns {Promise}
 */
export async function createReservationModal(type, startDate, endDate, category, room, onCreate) {
    startLoadingIndicator();
    ensureModalContainer('chooseModal', 'model-dialog_cus')
    await import('./components/CreateReservation');
    return $.ajax({
        url: '/calendar/createreservation_popup',
        type: 'POST',
        dataType: 'json',
        data: {
            type,
            startDate,
            endDate,
            category,
            room,
        },
        complete: () => {
            stopLoadingIndicator();
        },
        success: response => {
            $('#chooseModal .modal-content').html(response.html);
            $('#chooseModal').modal('show', {onCreate, type});
        },
        error: xhr => {
            alert('Error occured ' + xhr.status + ' ' + xhr.statusText);
        },
    });
}

/**
 * Similar to createReservationModal, but with only client data
 *
 * @param {string} type
 * @returns {Promise}
 */
export async function createReservationClientModal(type) {
    startLoadingIndicator();
    await import('./components/CreateReservationClient');
    return $.ajax({
        url: type === 'enquiry' ? '/enquiry/create' : '/reservation/create',
        type: 'GET',
        complete: () => {
            stopLoadingIndicator();
        },
        success: response => {
            $('#createReservationModal .modal-content').html(response);
            $('#createReservationModal').modal('show');
        },
        error: xhr => {
            console.error(xhr);
            alert('Error occured ' + xhr.status + ' ' + xhr.statusText);
        },
    });
}

/**
 * Opens a client search modal (duh).
 *
 * @param {callback} onSelect callback to call when a client is selected. Guaranteed to be called only once (no double clicks)
 * @param {?string} clientKey
 * @param {?object} filter
 */
export async function clientSearch(onSelect, clientKey= undefined, filter= undefined) {
    startLoadingIndicator();

    const $modalContainer = $('#getClientModal');
    if (!$modalContainer.length) {
        console.error('Cannot find #getClientModal');
        alert(translate('frontend', 'ERROR_INPROCESS'));
        stopLoadingIndicator();
        return false;
    }

    await import('./components/ClientSearch');
    stopLoadingIndicator();

    const data = {
        onSelect,
    };

    if (clientKey) {
        data.clientKey = clientKey;
    }

    if (filter) {
        data.filter = Object.fromEntries(Object.entries(filter).map(([key, value]) => {
            return [`Client[${key}]`, value];
        }));
    }

    $modalContainer.modal('show', data);
}

/**
 * @param {callback} onSelect callback to call when a client is selected. Returning `false` will prevent modal from closing.
 * @param {?object} filter graphql filter
 */
export function reservationSearchModal(onSelect, filter = {}) {
    showModal(
        import('./components/ReservationSearch').then(({ReservationSearch}) => ReservationSearch),
        {onSelect, filter},
        {size: 'model-dialog_cus'},
    );
}

/**
 * Displays a confirmation prompt, and returns a promise of the selected choice. When modal is closed without a choice, null is returned.
 *
 * When no actions are provided, will default to "confirm".
 *
 * @param {string} title
 * @param {*} message
 * @param {Object.<string, string|{label:string, variant:string}>} actions Selected key will be returned as a promise
 * @return {Promise<string|null>}
 */
export function confirmModal(title, message, actions = null) {
    return new Promise((resolve) => {
        showModal(
            import('./components/ConfirmModal').then(({ConfirmModal}) => ConfirmModal),
            {children: message, title, actions, onSelect: resolve},
            {keyboard: true},
        );
    });
}

/**
 * Display a dialog with an optional message prompting the user to input some text. When modal is closed without submitting, null is returned
 *
 * @param {string} title
 * @param {string} message
 * @param {string} defaultValue
 * @return {Promise<string|null>}
 */
export function promptModal(title, message, defaultValue = '') {
    return new Promise((resolve) => {
        showModal(
            import('./components/PromptModal').then(({PromptModal}) => PromptModal),
            {title, message, defaultValue, onReturn: resolve},
            {keyboard: true},
        );
    });
}

/**
 * @param {'auth'|'confirm'} initialStep
 */
export function reset2FAModal(initialStep) {
    let contentPromise;
    if (initialStep === 'confirm') {
        contentPromise = fetch('/userprofile/prepare-reset-otp', {method: 'POST'}).then((response) => {
            if (response.status === 422) {
                // The only errors can be about missing OTP.
                return null;
            } else {
                return response.json();
            }
        }).then(content => {
            return {initialOtpData: content};
        });
    } else {
        contentPromise = {initialOtpData: null};
    }

    showModal(
        import('./components/Reset2FAModal').then(({Reset2FAModal}) => Reset2FAModal),
        contentPromise,
        {keyboard: true},
    );
}

export function remove2FAModal() {
    showModal(
        import('./components/Remove2FAModal').then(({Remove2FAModal}) => Remove2FAModal),
        {},
        {keyboard: true},
    );
}


export function clientGdprInformationModal() {
    showModal(
        import('./components/ClientGdprInformationModal').then(({ClientGdprInformationModal}) => ClientGdprInformationModal),
        {},
        {animation: true, keyboard: true},
    );
}

/**
 * Wrapper around confirmModal(...), to build a formatted body message.
 * @param {Boolean} hasGuestProducts
 * @return {Promise<string|null>}
 */
export function removeGuestConfirmModal(hasGuestProducts) {
    let message;
    const actions = {};

    if (hasGuestProducts) {
        const removeSoloLabel = translate('frontend', 'REMOVE_GUEST_OPTION_KEEP_PRODUCTS_LABEL');
        const removeWithProductsLabel = translate('frontend', 'REMOVE_GUEST_OPTION_DELETE_PRODUCTS_LABEL');

        message = <dl>
            <dt>{removeSoloLabel}</dt>
            <dd>{translate('frontend', 'REMOVE_GUEST_OPTION_KEEP_PRODUCTS_DESCRIPTION')}</dd>

            <dt>{removeWithProductsLabel}</dt>
            <dd>{translate('frontend', 'REMOVE_GUEST_OPTION_DELETE_PRODUCTS_DESCRIPTION')}</dd>
        </dl>;

        actions.remove_solo = {
            label: removeSoloLabel,
            variant: 'danger',
        };

        actions.delete_with_products = {
            label: removeWithProductsLabel,
            variant: 'danger',
        };
    } else {
        // There's only one option so need to have both label+description
        message = <p>{translate('frontend', 'REMOVE_GUEST_CONFIRM_DESCRIPTION')}</p>;

        actions.remove_solo = {
            label: translate('frontend', 'REMOVE_GUEST_CONFIRM_LABEL'),
            variant: 'danger',
        };
    }

    return confirmModal(
        translate('frontend', 'REMOVE_GUEST_MODAL_TITLE'),
        message,
        actions,
    );
}

/**
 * This callback is called after successful reservation save.
 * @callback AddRoomsSavedCallback
 * @param {string} departureDate
 * @param {boolean} [selfCheckoutEnabled] exists only for reservations
 * @param {string} [selfCheckoutStatusHtml] exists only for reservations
 */

/**
 * Params that are passed when calling (add rooms) modal "show".
 * @typedef AddRoomsModalShowParams
 * @type {object}
 * @property {string} type
 * @property {string|number} reservationId
 * @property {string} clientKey
 * @property {AddRoomsSavedCallback} [onSaved]
 */

/**
 * Opens a full add rooms modal.
 *
 * @param {string} type
 * @param {string|number} reservationId
 * @param {AddRoomsSavedCallback} [onSaved]
 * @returns {Promise}
 */
export async function showAddRoomsModal(type, reservationId, onSaved) {
    startLoadingIndicator();

    const $modalContainer = $('#addRoomToReservationModal');
    if (!$modalContainer.length) {
        console.error('Cannot find #addRoomToReservationModal');
        alert(translate('frontend', 'ERROR_INPROCESS'));
        stopLoadingIndicator();
        return false;
    }

    await import('./components/AddRooms');

    return $.ajax({
        url: '/reservation/add-rooms-popup',
        type: 'POST',
        data: {
            type: type,
            reservation_id: reservationId,
        },
        complete: () => {
            stopLoadingIndicator();
        },
        /**
         * @param {object} response
         * @param {string} response.clientKey
         * @param {string} response.html
         */
        success: (response) => {
            $modalContainer.find(`.modal-content`).html(response.html);

            /** @type {AddRoomsModalShowParams} */
            const showParams = {
                type: type,
                reservationId: reservationId,
                clientKey: response.clientKey,
                // onSaved callback will be called on successful reservation save.
                onSaved: onSaved,
            };
            $modalContainer.modal('show', showParams);
        },
        error: xhr => {
            console.error(xhr);
            alert('Error occured ' + xhr.status + ' ' + xhr.statusText);
        },
    });
}

export function maintenanceModal(roomSetupId, onStatusChange = null) {
    showModal(
        import('./components/MaintenanceModal').then(({MaintenanceModal}) => MaintenanceModal),
        {
            roomSetupId,
            onStatusChange,
        },
    );
}

export function maintenanceTaskModal(maintenanceTaskId, onStatusChange = null) {
    showModal(
        import('./components/MaintenanceTask').then(({MaintenanceTask}) => MaintenanceTask),
        {
            maintenanceTaskId,
            onStatusChange,
        },
    );
}

export function maintenanceTaskWorklogModal(maintenanceTaskWorklogId, onSubmit = null) {
    showModal(
        import('./components/MaintenanceTaskWorklog').then(({MaintenanceTaskWorklog}) => MaintenanceTaskWorklog),
        {
            maintenanceTaskWorklogId,
            onSubmit,
        }
    );
}

export function maintenanceTaskWorklogExportModal({start, end}) {
    showModal(
        import('./components/MaintenanceTaskWorklogExport').then(({MaintenanceTaskWorklogExport}) => MaintenanceTaskWorklogExport),
        {
            initialValues: {
                start,
                end,
            },
        },
    );
}

/**
 * Params that are passed when calling (email send) modal "show".
 *
 * @typedef EmailSendModalShowParams
 * @type {object}
 * @property {string} emailSendPopupUrl
 * @property {object} emailSendPopupData
 * @property {string} sendEmailUrl
 * @property {function} [afterSendCallback]
 */

/**
 * Opens email send modal.
 *
 * @param {string} emailSendPopupUrl
 * @param {object} emailSendPopupData
 * @param {string} sendEmailUrl
 * @param {string} [language]
 * @param {function} [afterSendCallback]
 * @returns {Promise}
 */
export async function emailSendModal(emailSendPopupUrl, emailSendPopupData, sendEmailUrl, language, afterSendCallback) {
    startLoadingIndicator();

    ensureModalContainer('emailSendModal', 'model-dialog_cus', true);

    const componentPromise = import('./components/EmailSendModal');
    const contentPromise = $.post(emailSendPopupUrl, {
        ...emailSendPopupData,
        language: language ? language : '',
    });

    await Promise.all([componentPromise, contentPromise]).then(([, response]) => {
        $(`#emailSendModal`).find(`.modal-content`).html(response);

        /** @type {EmailSendModalShowParams} */
        const modalShowParams = {
            emailSendPopupUrl: emailSendPopupUrl,
            emailSendPopupData: emailSendPopupData,
            sendEmailUrl: sendEmailUrl,
            afterSendCallback: afterSendCallback,
        };
        $(`#emailSendModal`).modal('show', modalShowParams);
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

/**
 * Opens email preview modal (history email).
 *
 * @param {string|number} emailHistoryId
 * @returns {Promise}
 */
export async function emailPreviewModal(emailHistoryId) {
    startLoadingIndicator();

    ensureModalContainer('emailPreviewModal', 'model-dialog_cus', true);

    const componentPromise = import('./components/EmailPreviewModal');
    const contentPromise = $.get('/history-email/preview?id=' + emailHistoryId);

    await Promise.all([componentPromise, contentPromise]).then(([, response]) => {
        $(`#emailPreviewModal`).find(`.modal-content`).html(response);
        $(`#emailPreviewModal`).modal('show');
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

/**
 * @callback EditPersonModalOnSaved
 * @param {Object} contactPerson
 * @returns {Promise}
 */
/**
 * @typedef EditPersonModalShowParams
 * @type {object}
 * @property {?string} attachClientTo
 * @property {?string} attachmentParentKey
 * @property {'GUEST'|'PERSON'} [guestOrPerson='PERSON']
 * @property {function} [onClose]
 * @property {EditPersonModalOnSaved} [onSave]
 */
/**
 * Opens edit person modal.
 *
 * @param {'full'|'short'} fullOrShort
 * @param {null|string} [clientKey]
 * @param {null|string} [parentClientKey]
 * @param {null|'client'|'reservation'|'enquiry'|'client-split-invoice-screen'|'reservation-room'|'reservation-invoice-recipient'} [attachClientTo]
 * @param {null|string} [attachmentParentKey]
 * @param {function} [onClose]
 * @param {'GUEST'|'PERSON'} [guestOrPerson='PERSON']
 * @param {function} [beforeModalShow]
 * @param {EditPersonModalOnSaved} [onSave]
 * @param {string} scrollTo
 * @returns {Promise}
 */
export async function editPersonModal(fullOrShort, clientKey, parentClientKey, attachClientTo, attachmentParentKey, onClose, guestOrPerson, beforeModalShow, onSave, scrollTo) {
    startLoadingIndicator();

    ensureModalContainer('editPersonModal', '', false, true);

    const componentPromise = import('./components/EditPersonModal');
    let contentPromise;

    if (fullOrShort === 'full') {
        contentPromise = $.get('/client/show-edit-guest-full-form', {
            clientKey: clientKey,
        });
    } else if (fullOrShort === 'short') {
        contentPromise = $.get('/client/render-short-edit-client-form-modal-content', {
            clientKey: clientKey,
            parentClientKey: parentClientKey,
            attachClientTo: attachClientTo,
            attachmentParentKey: attachmentParentKey,
        });
    }

    await Promise.all([componentPromise, contentPromise]).then(([componentResponse, contentResponse]) => {
        const $modalContainer = componentResponse.default.$modalContainer;

        beforeModalShow && beforeModalShow($modalContainer);

        $modalContainer.find(`.modal-content`).html(contentResponse.html);

        if (scrollTo) {
            function handleModalShown() {
                const scrollToEl = $modalContainer.find(scrollTo).get(0);
                if (scrollToEl) {
                    scrollToEl.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
                $modalContainer.off('shown.bs.modal', handleModalShown);
            }

            $modalContainer.on('shown.bs.modal', handleModalShown);
        }


        /** @type {EditPersonModalShowParams} */
        const modalShowParams = {
            attachClientTo: contentResponse.attachClientTo,
            attachmentParentKey: contentResponse.attachmentParentKey,
            onClose: onClose,
            guestOrPerson: guestOrPerson ? guestOrPerson : 'PERSON',
            onSave: onSave,
        };
        $modalContainer.modal('show', modalShowParams);
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

/**
 * @callback EditClientModalOnEditCreateContact
 * @param {string} clientKey
 * @param {string} contactKey
 * @returns {Promise}
 */
/**
 * @typedef EditClientModalShowParams
 * @type {object}
 * @property {{
 *      client_id: number|undefined,
 *      client_key: string|undefined,
 *      is_company: 1|0|undefined
 *  }} client
 * @property {{
 *     client_id: number|undefined,
 *     client_key: string|undefined,
 *     full_name: string|undefined
 * }} contact
 * @property {function} [onSave]
 * @property {EditClientModalOnEditCreateContact} [onEditCreateContact]
 */
/**
 * Opens edit client modal.
 *
 * @param {number|string} [clientId]
 * @param {function} [onBeforeShow]
 * @param {function} [onSave]
 * @param {number|string} [contactId]
 * @param {EditClientModalOnEditCreateContact} [onEditCreateContact]
 * @returns {Promise}
 */
export async function editClientModal(clientId, onBeforeShow, onSave, contactId, onEditCreateContact) {
    startLoadingIndicator();

    ensureModalContainer('editClientModal');

    const componentPromise = import('./components/EditClientModal');
    const contentPromise = $.get('/client/client-modal', {client_id: clientId, contact_id: contactId ? contactId : ''});

    await Promise.all([componentPromise, contentPromise]).then(([componentResponse, contentResponse]) => {
        const $modalContainer = componentResponse.default.$modalContainer;

        $modalContainer.find(`.modal-content`).html(contentResponse.html);

        /** @type {EditClientModalShowParams} */
        const modalShowParams = {
            client: contentResponse.client || {},
            contact: contentResponse.contact || {},
            onSave: onSave,
            onEditCreateContact: onEditCreateContact,
        };

        if (onBeforeShow) {
            if (onBeforeShow($modalContainer) === false) {
                // Modal can be canceled in split invoice when section is disabled.
                return;
            }
        }

        $modalContainer.modal('show', modalShowParams);
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

export function reservationDepositModal(reservationKey) {
    showModal(
        import('./components/AddReservationDeposit').then(({AddReservationDeposit}) => AddReservationDeposit),
        {reservationKey},
        {animation: true},
    );
}

export function reservationStripeDepositModal(reservationKey, reservationGross, paymentMethod, brand, last4, expiry) {
    showModal(
        import('./components/AddReservationStripeDeposit').then(({AddReservationStripeDeposit}) => AddReservationStripeDeposit),
        {reservationKey, reservationGross, paymentMethod, brand, last4, expiry},
        {animation: true},
    );
}

export function reservationStripeCardModal(reservationId) {
    showModal(
        import('./components/AddReservationStripeCardModal').then(({AddReservationStripeCardModal}) => AddReservationStripeCardModal),
        {reservationId},
        {animation: true},
    );
}

export async function verifyPassword(onVerify) {
    startLoadingIndicator();
    ensureModalContainer('verifyPasswordModal', 'modal-sm', true);

    const componentPromise = import('./components/VerifyPassword');
    const contentPromise = $.get('/site/verify-password-template');

    await Promise.all([componentPromise, contentPromise]).then(([, response]) => {
        $('#verifyPasswordModal').find(`.modal-content`).html(response);
        $('#verifyPasswordModal').modal('show', {
            onVerify,
        });
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

export function viewTssPin(tssId) {
    ensureModalContainer('tssPinModal', 'modal-sm', true);

    return $.ajax({
        url: `/basic-settings/tss-pin?tssId=${tssId}`,
        method: 'GET',
        success: () => {
            startLoadingIndicator();
        },
        complete: () => {
            stopLoadingIndicator();
        },
    }).then((response) => {
        $('#tssPinModal').find(`.modal-content`).html(response);
        $('#tssPinModal').modal('show');
    }, (error) => {
        console.error(error);
        if (error.status === 400) {
            return error;
        } else {
            alert(error instanceof Error ? error : error.statusText);
        }
    });
}

/**
 * @typedef TouristTaxModalShowParams
 * @type {object}
 * @property {string} reservationKey
 */
/**
 * @param {string} reservationKey
 * @param {boolean} isReload true: internal modal reload - won't fetch status from AVS
 * @returns {Promise}
 */
export function touristTaxModal(reservationKey, isReload) {
    startLoadingIndicator();

    ensureModalContainer('touristTaxModal', 'model-dialog_cus', true);

    const componentPromise = import('./components/TouristTaxModal');
    const contentPromise = $.get('/reservation/tourist-tax-modal', {reservationKey, isReload: isReload ? '1': '0'});

    return Promise.all([componentPromise, contentPromise])
        .then(
            ([, response]) => {
                const $modalContainer = $('#touristTaxModal');

                $modalContainer.find('.modal-content').html(response);

                /** @type {TouristTaxModalShowParams} */
                const modalShowParams = {
                    reservationKey: reservationKey,
                };
                $modalContainer.modal('show', modalShowParams);
            },
            (error) => {
                console.error(error);
                alert(error instanceof Error ? error : error.statusText);
            },
        )
        .finally(() => stopLoadingIndicator());
}

export function DSFinVKModal() {
    startLoadingIndicator();

    ensureModalContainer('DSFinVKModal');

    const componentPromise = import('./components/DsFinVK');
    const contentPromise = $.get('/basic-settings/ds-finv-k-modal');

    return Promise.all([componentPromise, contentPromise]).then(([, content]) => {
        const $modalContainer = $('#DSFinVKModal');
        $modalContainer.find('.modal-content').html(content);
        $modalContainer.modal('show');
    }).finally(() => stopLoadingIndicator());
}

/**
 * @typedef EditSpecialEventModalShowParams
 * @type {object}
 * @property {function} [onSave]
 * @property {function} [onClose]
 */
/**
 * @param {object} data
 * @param {string} [data.specialId]
 * @param {string} [data.startDate]
 * @param {string} [data.endDate]
 * @param {function} [onSave]
 * @param {function} [onClose]
 * @return {Promise<void>}
 */
export function editSpecialEventModal(data, onSave, onClose) {
    startLoadingIndicator();

    ensureModalContainer('editSpecialEventModal', 'modal-lg');

    const componentPromise = import('./components/SpecialEventModal');
    const contentPromise = $.get('/calendar/createreservation_popup_special', {
        special_id: data.specialId,
        start_date: data.startDate,
        end_date: data.endDate,
    });

    return Promise.all([componentPromise, contentPromise]).then(([, content]) => {
        const $modalContainer = $('#editSpecialEventModal');
        $modalContainer.find(`.modal-content`).html(content);
        /** @type {EditSpecialEventModalShowParams} */
        const modalShowParams = {
            onSave: onSave,
            onClose: onClose,
        };
        $modalContainer.modal('show', modalShowParams);
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

export function syncCmmModal() {
    showModal(import('./components/SyncCmmModal').then(({SyncCmmModal}) => SyncCmmModal));
}

export async function roomDetailsModal(id) {
    startLoadingIndicator();

    const componentPromise = import('./components/RoomDetails');
    const contentPromise = $.post('/reservation/reservation_popup', {reservation_id: id});

    ensureModalContainer('chooseModal', 'model-dialog_cus');
    return Promise.all([componentPromise, contentPromise]).then(([, content]) => {
        const $modalContainer = $('#chooseModal');
        $modalContainer.find('.modal-content').html(content);
        $modalContainer.modal('show');
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

export async function enquiryRoomDetailsModal(id) {
    startLoadingIndicator();

    const componentPromise = import('./components/EnquiryRoomDetails');
    const contentPromise = $.post('/enquiry/reservation_popup', {reservation_id: id});

    ensureModalContainer('chooseModal', 'model-dialog_cus');
    return Promise.all([componentPromise, contentPromise]).then(([, content]) => {
        const $modalContainer = $('#chooseModal');
        $modalContainer.find('.modal-content').html(content);
        $modalContainer.modal('show');
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

/**
 * @param {number|string} id
 * @param {"reservation"|"enquiry"} type
 */
export async function productModal(id, type) {
    startLoadingIndicator();

    const componentPromise = import('./components/ProductModal');
    const contentPromise = $.post(`/${type}/edit_product_service_list`, {res_product_service_id: id});

    ensureModalContainer('product_service_detailsModal', 'modal-lg', true, true);
    return Promise.all([componentPromise, contentPromise]).then(([, content]) => {
        if (content.status === 'ok') {
            const $modalContainer = $('#product_service_detailsModal');
            $modalContainer.find('.modal-content').html(content.html);
            $modalContainer.modal('show', {type});
        }
    }, (error) => {
        console.error(error);
        alert(error instanceof Error ? error : error.statusText);
    }).finally(() => {
        stopLoadingIndicator();
    });
}

export function hestaLodgingStatisticsExportModal(month, year, companyBurNumber) {
    showModal(
        import('./components/HestaLodgingStatisticsExport').then(({HestaLodgingStatisticsExport}) => HestaLodgingStatisticsExport),
        {
            initialValues: {
                month,
                year,
                companyBurNumber
            },
        },
    );
}

export function channelManagerNoticesModal(reservation = null) {
    showModal(
        import('./components/ChannelManagerNoticesModal').then(({ChannelManagerNoticesModal}) => ChannelManagerNoticesModal),
        {reservation},
        {size: 'modal-lg'},
    );
}

export function romantikDailyExportModal({date}) {
    showModal(
        import('./components/RomantikDailyExportModal').then(({RomantikDailyExportModal}) => RomantikDailyExportModal),
        {
            initialValues: {
                date,
            },
        },
    );
}

/**
 * @returns {Promise<boolean>} Will resolve to `true` when there were any payment changes
 */
export function invoicePaymentHistoryModal(invoiceId) {
    return new Promise((resolve) => {
        showModal(
            import('./components/InvoicePaymentHistoryModal').then(({InvoicePaymentHistoryModal}) => InvoicePaymentHistoryModal),
            {invoiceId, onClose: resolve},
            {size: 'modal-lg'},
        );
    });
}

/**
 * @returns {Promise<boolean>} Will resolve to `true` when a payment was cancelled
 */
export function incomingPaymentCancelModal(incomingPaymentId, preload = false) {
    const componentPromise = import('./components/IncomingPaymentCancelModal').then(({IncomingPaymentCancelModal}) => IncomingPaymentCancelModal);

    return new Promise((resolve) => {
        const reference = {__typename: 'IncomingPayment', id: incomingPaymentId};
        const props = {reference, onAfterSubmit: resolve};

        let propsPromise;
        if (!preload) {
            propsPromise = props;
        } else {
            // IncomingPaymentCancelModal expects data to already exist in apollo. If
            // this is called from a screen that may not have the incoming payment
            // loaded already, pass preload:true argument
            propsPromise = Promise.all([
                import('./graphql'),
                import('@apollo/client'),
                componentPromise,
            ]).then(async ([{apollo}, {gql}, component]) => {
                await apollo.query({
                    query: gql`
                        query IncomingPaymentNumber($incomingPaymentId:ID!) {
                            incomingPayments(filter:{id:{eq:$incomingPaymentId}} first:1) {
                                edges {
                                    node {
                                        ...IncomingPaymentNumber
                                    }
                                }
                            }
                        },
                        ${component.fragment}
                    `,
                    variables: {
                        incomingPaymentId,
                    },
                });

                return props;
            })
        }

        showModal(
            componentPromise,
            propsPromise,
        );
    });
}

export function datevConnectionStatusModal() {
    showModal(import('./components/DatevConnectionStatusModal').then(({DatevConnectionStatusModal}) => DatevConnectionStatusModal));
}

export function fiskalyV2ExportModal(tssId) {
    showModal(
        import('./components/FiskalyV2Export').then(({FiskalyV2ExportModal}) => FiskalyV2ExportModal),
        {tssId},
    );
}

export function roomBlockCreate(roomSetupId, start, end) {
    showModal(
        import('./components/RoomBlockCreate').then(({RoomBlockCreate}) => RoomBlockCreate),
        {roomSetupId, start, end},
    );
}

export function roomBlockUpdate(roomBlockId, start, end, title) {
    showModal(
        import('./components/RoomBlockUpdate').then(({RoomBlockUpdate}) => RoomBlockUpdate),
        {roomBlockId, start, end, title},
    );
}

export function finanzOnlineAuthModal() {
    showModal(import('./components/Fiskaly/FinanzOnlineAuthenticate').then(({FinanzOnlineAuthenticate}) => FinanzOnlineAuthenticate));
}

export function fiskalyDEP7Modal(cashRegisterUuid) {
    showModal(
        import('./components/Fiskaly/FiskalyDEPExport').then(({FiskalyDEPExportModal}) => FiskalyDEPExportModal),
        {cashRegisterUuid},
    );
}

export function logoUploadModal() {
    showModal(import('./components/LogoUploadModal').then(({LogoUploadModal}) => LogoUploadModal));
}

export function specialEventsImportModal() {
    showModal(import('./components/SpecialEventsImportModal').then(({SpecialEventsImportModal}) => SpecialEventsImportModal));
}

export function bulkOpenCloseCategoriesModal() {
    showModal(import('./components/BulkOpenCloseCategoriesModal').then(({BulkOpenCloseCategoriesModalModal}) => BulkOpenCloseCategoriesModalModal));
}

export function vostioEncodeModal(roomKey, addKeys = false) {
    return new Promise((resolve) => {
        showModal(
            import('./components/VostioEncodeModal').then(({VostioEncodeModal}) => VostioEncodeModal),
            {roomKey, onClose: resolve, addKeys},
        );
    });
}

export function vostioViewKeysModal(roomKey) {
    return new Promise((resolve) => {
        showModal(
            import('./components/VostioViewKeysModal').then(({VostioViewKeysModal}) => VostioViewKeysModal),
            {roomKey, onClose: resolve},
            {size: 'modal-lg'},
        );
    });
}

export function vostioReadCardModal() {
    showModal(import('./components/VostioReadCardModal').then(({VostioReadCardModal}) => VostioReadCardModal));
}
