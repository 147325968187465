import {useState} from 'react';
import useEventListener from '../hooks/useEventListener';
import ChannelManagerNotice from './ChannelManagerNotice';

export default function ChannelManagerNoticesList({initialNotices, isOpenable = false}) {
    const [notices, setNotices] = useState(initialNotices);

    const changeStatus = (notice) => {
        setNotices(notices => {
            if (notice.resolved_at !== null) {
                // Only unresolved notices are shown
                return notices.filter(element => element.notice_key !== notice.notice_key);
            } else {
                const newNotices = [...notices];
                const noticeIdx = notices.findIndex(element => element.notice_key === notice.notice_key);
                if (noticeIdx !== -1) {
                    newNotices[noticeIdx] = notice;
                } else {
                    newNotices.push(notice);
                }
                return newNotices;
            }
        });
    };

    useEventListener('channelManagerNotice:update', (event) => {
        changeStatus(event.detail);
    });

    if (notices.length === 0) {
        // Avoid adding margin for empty lists
        return null;
    }

    return notices.map(notice => <ChannelManagerNotice key={notice.notice_key} notice={notice} afterChangeStatus={changeStatus} isOpenable={isOpenable}/>);
}
