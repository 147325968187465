import {translateRaw} from '../utils';

export default function ChannelManagerNoticeDescription({description: description}) {
    if (typeof description === 'object') {
        const otherTokens = {};
        const actionTokens = {};

        for (const [tokenKey, tokenValue] of Object.entries(description.tokens)) {
            if (typeof tokenValue === 'object' && 'url' in tokenValue) {
                actionTokens[`{${tokenKey}}`] = tokenValue;
            } else {
                otherTokens[tokenKey] = tokenValue;
            }
        }

        description = translateRaw(description.category, description.message, otherTokens).map((token, i) => {
            if (token in actionTokens) {
                const actionToken = actionTokens[token];
                return <a key={i} href={actionToken.url} {...(actionToken.options || [])}>{actionToken.text}</a>;
            } else {
                return <span key={i}>{token}</span>;
            }
        });
    }

    return description;
}
