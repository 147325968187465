import {translate} from '../utils';
import InlineLoadingSpinner from './InlineLoadingSpinner';

export function ModalHeader({title}) {
    return <div className="modal-header">
        <h4 className="modal-title">{title}</h4>

        <button type="button" className="close" data-dismiss="modal">
            <i className="fa fa-times"></i>
        </button>
    </div>;
}

export function ModalBody({overflowing, children}) {
    return <div className={'modal-body' + (overflowing ? ' modal-body--overflowing' : '')}>
        {children}
    </div>;
}

export function ModalFooter() {
    return <div className="modal-footer">
        <CloseBtn/>
    </div>;
}

export function CloseBtn() {
    return <button type="button" className="btn btn-default" data-dismiss="modal">{translate('frontend', 'CLOSE')}</button>;
}

export function LoadingModalContent() {
    return <div className="modal-header overlay-wrapper select-none">
        <button type="button" className="close" data-dismiss="modal" style={{position: 'relative', zIndex: 51}}>
            <i className="fa fa-times"></i>
        </button>
        <div className="overlay"><InlineLoadingSpinner size={'large'}/></div>
    </div>;
}

export function ErrorModalContent({message}) {
    return <>
        <ModalBody>
            <div className="alert alert-danger">{message}</div>
        </ModalBody>
        <ModalFooter/>
    </>;
}
